import React, { useState, useRef, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import LightTheme from "../themes/lightTheme";
import { ThemeProvider } from "styled-components";
import Navigation from "../components/Nav";
import Footer from "../components/Footer";
import {Favorites} from "../components/global/Favorites";
import FavoritesContext from "../context/favorites";
import PixiContext from '../context/pixiContext'
import ActivePatternContext from '../context/activePattern'
import ScreenshotModeContext from "../context/screenshotMode";
import PageIntroContext from "../context/pageIntro";
import { useScreenshot , createFileName } from "../hooks/useScreenshot"
import usePixi from '../components/Pixi/usePixi'
import {BrowserView, MobileView} from 'react-device-detect';
import MobileWarning from './MobileWarning'


const GlobalStyle = createGlobalStyle`
  * {  
    box-sizing: border-box;
    padding:0px;
    margin:0px;
    font-family:sans-serif;
  }
  body {
    overflow: hidden;
    font-family:'Avenir Next' !important;
  }
  h1,h2,h3,h4,h5 {
    font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
    font-weight: normal; 
    font-style: normal;
  }
  p{
    font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
    line-height: 1.6;
    font-weight:300;
  }
  li{
    font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
  }
`;

const MainWrapper = styled.main`
  top:0px;
  z-index:10;
  display: inline-block;
`;

const AppWrapper = styled.div`
  ${props => props.screenshotMode ? "height: 100vh; width: 100vw; ":  "display: inline-block;"}
`

const Layout = ({ children, location }) => {

  const { PIXI, loading, allData, progress, annotationData} = usePixi()
  const [viewport, setViewport] = useState("");
  const [infoBoxes , setInfoBoxes ] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [screenshotMode, setScreenshotMode] = useState(false);
  const [displayPageIntro, setDisplayPageIntro] = useState({entdecken:true,entwerfen:true,generieren:true});
  const screenshotRef = useRef(null)
  const [activePatterns, setActivePatterns] = useState([])
  const [cloudMode, setCloudMode] = useState(0)
  const [pixiApp, setPixiApp] = useState("")
  const [showTooltip, setShowTooltip] = useState(true)
  const [infoBoxContent, setInfoBoxContent ] = useState("")
  // dont remove image!!
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = "img", extension = "jpg" }) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
    setScreenshotMode(false)
  };
 
  useEffect(() => {
    if(screenshotMode){
      takeScreenShot(screenshotRef.current).then((image) => download(image,{name:"timbayern_calico_entwerfen"}));
    }
  },[takeScreenShot,screenshotMode])
  
  return (
    <>
      <BrowserView>
        <AppWrapper ref={screenshotRef} screenshotMode={screenshotMode}>
        <ThemeProvider theme={LightTheme}>
          <PixiContext.Provider 
            value={{
              PIXI,
              loading, 
              allData, 
              progress, 
              annotationData,
              viewport, setViewport, 
              infoBoxes , setInfoBoxes, 
              pixiApp, setPixiApp,
              cloudMode, setCloudMode,
              showTooltip, setShowTooltip,
              infoBoxContent, setInfoBoxContent
              }}
            >
            <ScreenshotModeContext.Provider value={{ screenshotMode, setScreenshotMode }}>
              <GlobalStyle />
              {location?.pathname !== "/" &&  !screenshotMode && 
                <>
                  <Navigation screenshotMode={screenshotMode} currentPage={location.pathname} />
                  <Footer></Footer> 
                </>
              }
              <FavoritesContext.Provider value={{ favorites, setFavorites }}>
                <PageIntroContext.Provider value={{ displayPageIntro, setDisplayPageIntro }}>
                <ActivePatternContext.Provider value={{ activePatterns, setActivePatterns }}>
                  {location.pathname !== "/" &&  !screenshotMode && 
                    <Favorites location={location}/>
                  }
                <MainWrapper >{children}</MainWrapper>
                </ActivePatternContext.Provider>
                </PageIntroContext.Provider>
              </FavoritesContext.Provider>
              
            </ScreenshotModeContext.Provider>
          </PixiContext.Provider>
        </ThemeProvider>
        </AppWrapper>
      </BrowserView>
      <MobileView >
        <MobileWarning/>
      </MobileView>
    </>
  );
};

export default Layout;



