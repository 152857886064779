import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import BgImage from '../images/mobile-warning-background.jpg'
import BgVideoSrc from '../video/bgvideo2.mp4'


const PopupWrapper = styled.div`

  background-color:white;
  padding:20px;
  z-index:10;
  position:absolute;
  height: fit-content;
  top:25%;
  margin:0 10px;
`

const TopWrapper = styled.div`
  width:100%;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  >h1{
    font-weight:bold;
    text-transform: uppercase;
    margin:0;
    font-size:1.4rem;
  }
`

const Wrapper = styled.div`
  background-color:red;
  top:0px;
  z-index:9999;
  position: fixed;
  height:100vh;
  width: 100vw;
  overflow: hidden;
}
`
const TextWrapper = styled.div`
  padding:10px 0;
  a{
    color:black;
    font-family: "Avenir Next";
  }
`
const Video = styled.video`
  top:0;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
`

const MobileWarning = () => {
/*   useEffect(()=>{
      function myFunction() {
      if (video.paused) {
      video.play();
      btn.innerHTML = "Pause";
      } else {
      video.pause();
      btn.innerHTML = "Play";
      }
      }

  },[]) */
/*   return (
    <div>
    <BackgroundWrapper>
      <PopupWrapper>
        <TopWrapper>
          <h1>Calico</h1>
        </TopWrapper>
       <TextWrapper>
        <p>
          Der Besuch dieser interaktiven Muster-Plattform wird Dir mit einem größeren Bildschirm viel Freude bereiten! 
        </p>
        <br></br>
        <p>
          Bitte benutze Deinen Computer und gehe auf: calico.timbayern.de
        </p>
       </TextWrapper>
        
      </PopupWrapper>
    </BackgroundWrapper>
    </div>
  ); */

return(
  <Wrapper>
    <Video  muted autoPlay playsInline loop>
      <source src={BgVideoSrc} type="video/mp4" /> 
    </Video>
    <PopupWrapper>
      <TopWrapper><h1>Calico</h1></TopWrapper>
      <TextWrapper>
        <p>Der Besuch dieser interaktiven Muster-Plattform wird Dir mit einem größeren Bildschirm viel Freude bereiten!</p>
        <br></br>
        <p>Bitte benutze Deinen Computer und gehe auf: calico.timbayern.de</p>
      </TextWrapper>
    </PopupWrapper>

  </Wrapper>
)

};

export default MobileWarning